@media only screen and (max-width: 600px) {
    .Header span{
        min-width: 100%;
        text-align: center;
    }
    .Header>nav>span>a{
        min-width: 100% !important;
        text-align: center;
        padding: 10px 0px;
        margin: 0 !important;
    }
}

.Header{
    background-color: #181818;
    color: #fff;
    min-height: 40px;
    padding: 10px 0px;
    display: flex;
    flex-flow:row wrap;
    justify-content: space-around;
    align-items: center;
    border-top: 2px solid #E63946;
    box-shadow: 3px 3px 6px #000;
}

.Header>nav>span{
    display: flex;
    flex-flow:row wrap;
    justify-content: space-between;
    align-items: center;
}

.Header>nav>span>a{
    text-decoration: none;
    color: white;
    font-size: 20px;
    margin: 0 15px;
}
.Header>nav>span>a:hover{
    color: #E63946;
}