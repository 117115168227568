@media only screen and (max-width: 600px) {
  .row > div {
    width: 100% !important;
    min-height: 95vh;
  }
}

.contact {
  border-top: 3px solid #e63946;
  min-height: 100vh;
}
.title {
  background-color: #181818;
  color: #fff;
  padding: 15px;
}
.title h1 {
  border-bottom: 2px solid #e63946;
  width: fit-content;
  font-size: 25px;
  margin-left: 20px;
}
.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  background-color: #f2f2f2;
  overflow: hidden;
}
.row > div {
  width: 50%;
  min-height: 95vh;
}
.contact_form {
  border-radius: 5px;
  padding: 20px;
}
.contact_form form {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
  min-height: 95vh;
}
.contact_form h2 {
  text-align: center;
  width: 100%;
  color: #e63946;
}
form>div{
    width: 80%;
}
input,
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="button"] {
  background-color: #e63946;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 80%;
}

input[type="button"]:hover {
  background-color: #bd313d;
}

.contact_info {
  background-color: #cf3c49;
  color: white;
}
.contact_info > h2 {
  text-align: center;
  width: 100%;
  padding: 20px 0px;
}
.contact_info > ul {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  min-height: 95vh;
}
.contact_info li {
  list-style: none;
  text-align: center;
  width: 70%;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}
.contact_info li:nth-child(1) {
  background-color: #3b5998;
}
.contact_info li:nth-child(2) {
  background-color: #00acee;
}
.contact_info li:nth-child(3) {
  background-color: #211f1f;
}
.contact_info li:nth-child(4) {
  background-color: grey;
}

.contact_info a {
  margin: 10px 0px;
  color: white;
  text-decoration: none;
}
