@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@media only screen and (max-width: 600px) {
    .Header_Header__2C_Ub span{
        min-width: 100%;
        text-align: center;
    }
    .Header_Header__2C_Ub>nav>span>a{
        min-width: 100% !important;
        text-align: center;
        padding: 10px 0px;
        margin: 0 !important;
    }
}

.Header_Header__2C_Ub{
    background-color: #181818;
    color: #fff;
    min-height: 40px;
    padding: 10px 0px;
    display: flex;
    flex-flow:row wrap;
    justify-content: space-around;
    align-items: center;
    border-top: 2px solid #E63946;
    box-shadow: 3px 3px 6px #000;
}

.Header_Header__2C_Ub>nav>span{
    display: flex;
    flex-flow:row wrap;
    justify-content: space-between;
    align-items: center;
}

.Header_Header__2C_Ub>nav>span>a{
    text-decoration: none;
    color: white;
    font-size: 20px;
    margin: 0 15px;
}
.Header_Header__2C_Ub>nav>span>a:hover{
    color: #E63946;
}
#Hero_hero__2jLCD {
  background-image: url(/static/media/hero_bg.0af8ad9f.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  border-bottom: 2px solid #e63946;
}

#Hero_hero__2jLCD > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

#Hero_hero__2jLCD > div h1 {
  font-size: 3rem;
  color: #e63946;
  border-bottom: 1px solid #e63946;
  margin-bottom: 10px;
}

#Hero_hero__2jLCD > div span {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.Services_services__2DDZL {
  min-height: 100vh;
}
.Services_title__2KBva {
  background-color: #181818;
  color: #fff;
  padding: 15px;
}
.Services_title__2KBva h1 {
  border-bottom: 2px solid #e63946;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 25px;
  margin-left: 20px;
}

.Services_row__3cr1W {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  min-height: 90vh;
  overflow: hidden;
}
.Services_row__3cr1W > div {
  flex: 1 1;
  min-width: 300px;
  margin-bottom: 30px;
  overflow: hidden;
}
.Services_row__3cr1W > div > img {
  padding: 25px 0;
}
.Services_myBtn__3ptEN {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: 1px solid #e63946;
  background-color: #181818;
  color: #e63946;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
}

.Services_myBtn__3ptEN:hover {
  background-color: #555; 
}

.Portfolio_portfolio__334g1{
  border-top: 3px solid #e63946;
}
.Portfolio_title__27152 {
  background-color: #181818;
  color: #fff;
  padding: 15px;
}
.Portfolio_title__27152 h1 {
  border-bottom: 2px solid #e63946;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 25px;
  margin-left: 20px;
}
@media only screen and (max-width: 600px) {
  .Contact_row__3PhTj > div {
    width: 100% !important;
    min-height: 95vh;
  }
}

.Contact_contact__1m2re {
  border-top: 3px solid #e63946;
  min-height: 100vh;
}
.Contact_title__C6auq {
  background-color: #181818;
  color: #fff;
  padding: 15px;
}
.Contact_title__C6auq h1 {
  border-bottom: 2px solid #e63946;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 25px;
  margin-left: 20px;
}
.Contact_row__3PhTj {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  background-color: #f2f2f2;
  overflow: hidden;
}
.Contact_row__3PhTj > div {
  width: 50%;
  min-height: 95vh;
}
.Contact_contact_form__3MQIJ {
  border-radius: 5px;
  padding: 20px;
}
.Contact_contact_form__3MQIJ form {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
  min-height: 95vh;
}
.Contact_contact_form__3MQIJ h2 {
  text-align: center;
  width: 100%;
  color: #e63946;
}
form>div{
    width: 80%;
}
input,
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="button"] {
  background-color: #e63946;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 80%;
}

input[type="button"]:hover {
  background-color: #bd313d;
}

.Contact_contact_info__3ob8J {
  background-color: #cf3c49;
  color: white;
}
.Contact_contact_info__3ob8J > h2 {
  text-align: center;
  width: 100%;
  padding: 20px 0px;
}
.Contact_contact_info__3ob8J > ul {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  min-height: 95vh;
}
.Contact_contact_info__3ob8J li {
  list-style: none;
  text-align: center;
  width: 70%;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}
.Contact_contact_info__3ob8J li:nth-child(1) {
  background-color: #3b5998;
}
.Contact_contact_info__3ob8J li:nth-child(2) {
  background-color: #00acee;
}
.Contact_contact_info__3ob8J li:nth-child(3) {
  background-color: #211f1f;
}
.Contact_contact_info__3ob8J li:nth-child(4) {
  background-color: grey;
}

.Contact_contact_info__3ob8J a {
  margin: 10px 0px;
  color: white;
  text-decoration: none;
}

.Loader_loader__1uQi3 {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .Loader_loader__1uQi3 div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #e63946;
    -webkit-animation: Loader_loader__1uQi3 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
            animation: Loader_loader__1uQi3 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .Loader_loader__1uQi3 div:nth-child(1) {
    left: 8px;
    -webkit-animation-delay: -0.24s;
            animation-delay: -0.24s;
  }
  .Loader_loader__1uQi3 div:nth-child(2) {
    left: 32px;
    -webkit-animation-delay: -0.12s;
            animation-delay: -0.12s;
  }
  .Loader_loader__1uQi3 div:nth-child(3) {
    left: 56px;
    -webkit-animation-delay: 0;
            animation-delay: 0;
  }
  @-webkit-keyframes Loader_loader__1uQi3 {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  @keyframes Loader_loader__1uQi3 {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  
.Footer_footer__1w0lV{
    background-color: #181818;
    color: #fff;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}
.App {
  font-family: "Roboto", sans-serif;
}
