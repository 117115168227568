.portfolio{
  border-top: 3px solid #e63946;
}
.title {
  background-color: #181818;
  color: #fff;
  padding: 15px;
}
.title h1 {
  border-bottom: 2px solid #e63946;
  width: fit-content;
  font-size: 25px;
  margin-left: 20px;
}