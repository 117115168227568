#hero {
  background-image: url("../../assets/img/hero_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  border-bottom: 2px solid #e63946;
}

#hero > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

#hero > div h1 {
  font-size: 3rem;
  color: #e63946;
  border-bottom: 1px solid #e63946;
  margin-bottom: 10px;
}

#hero > div span {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}
