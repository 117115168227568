@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}
.App {
  font-family: "Roboto", sans-serif;
}