.services {
  min-height: 100vh;
}
.title {
  background-color: #181818;
  color: #fff;
  padding: 15px;
}
.title h1 {
  border-bottom: 2px solid #e63946;
  width: fit-content;
  font-size: 25px;
  margin-left: 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  min-height: 90vh;
  overflow: hidden;
}
.row > div {
  flex: 1;
  min-width: 300px;
  margin-bottom: 30px;
  overflow: hidden;
}
.row > div > img {
  padding: 25px 0;
}
.myBtn {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: 1px solid #e63946;
  background-color: #181818;
  color: #e63946;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
}

.myBtn:hover {
  background-color: #555; 
}
